// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import { makeActiveLocation } from '../../actions/locations';
import { flyToCoordinates } from '../../actions/map';
import { Section } from '../layouts';

// Config

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN

// Header

class Map extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.initializeMap = this.initializeMap.bind(this);
    this.createMap = this.createMap.bind(this);
    this.createMapData = this.createMapData.bind(this);
    this.flyTo = this.flyTo.bind(this);
  }

  static propTypes = {
    locations: PropTypes.array.isRequired,
    flyTo: PropTypes.object.isRequired,
    flyToCoordinates: PropTypes.func.isRequired,
    makeActiveLocation: PropTypes.func.isRequired,
  }

  initialState() {
    return {
      map: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locations.length === 0 && this.props.locations.length > 0) {
      this.setState({map: this.initializeMap()});
    }
    if (prevProps.flyTo !== this.props.flyTo) {
      const coordinates = [this.props.flyTo.longitude, this.props.flyTo.latitude]
      this.flyTo(coordinates)
    }
  }

  initializeMap() {
    var map = this.createMap();
    var mapData = this.createMapData(map);
    map.on('load', function(e) {
      map.addSource('places', {
        type: 'geojson',
        data: mapData
      });
    });
    this.addMarkers(map, this.props.makeActiveLocation, this.props.flyToCoordinates);
    map.addControl(new mapboxgl.NavigationControl());
    return map;
  }

  addMarkers(map, makeActiveLocation, flyToCoordinates) {
    this.props.locations.forEach(loc => {
      var el = document.createElement('div');
      el.addEventListener('click', function(e) {
        const coor = {
          longitude: loc.data.longitude,
          latitude: loc.data.latitude
        };
        flyToCoordinates(coor);
        makeActiveLocation(loc.data.google_place_id[0].text);
        e.stopPropagation();
      });
      // Add a class called 'marker' to each div
      el.className = 'marker';
      el.style.backgroundImage = `url(${loc.data.icon_image.url})`;
      // By default the image for your custom marker will be anchored
      // by its center. Adjust the position accordingly
      // Create the custom markers, set their position, and add to map
      new mapboxgl.Marker(el, { offset: [0, -23] })
        .setLngLat([loc.data.longitude, loc.data.latitude])
        .addTo(map);
    })
  }

  flyTo(coordinates) {
    let zoomLevel = 15
    if (coordinates[0] === -115.1487986 && coordinates[1] === 36.167387) {
      zoomLevel = 13
    }
    this.state.map.flyTo({
      center: coordinates,
      zoom: zoomLevel
    });
  }

  createMap() {
    var map =  new mapboxgl.Map({
      // container id specified in the HTML
      container: 'map',
      // style URL
      style: 'mapbox://styles/deepspaceprogram/ck36rdrgm05nc1co1wktrl1ss',
      // initial position in [lon, lat] format
      center: [-115.1487986, 36.167387],
      // initial zoom
      zoom: 13
    });
    return map
  }

  createMapData(map) {
    var mapData = {
      "type": "FeatureCollection",
      "features": []
    }
    this.props.locations.forEach(loc => {
      const locData = {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            loc.data.longitude,
            loc.data.latitude
          ]
        },
        "properties": {
          "title": loc.data.title[0].text,
        }
      }
      mapData.features.push(locData)
    });
    return mapData;
  }

  render() {
    return (
      <Section height="100%" width="100%" id="map">
      </Section>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.locationsAndPlacesReducer.locations,
  flyTo: state.mapReducer.flyTo
})

Map.defaultProps = {
  locations: [],
  flyTo: {}
}

export default connect(mapStateToProps, { makeActiveLocation, flyToCoordinates })(Map);

// Colors

export const colors = {
  accent: '#30E1A5',
  black: '#231F20',
  offWhite: '#fafafa',
  white: '#fff',
  transparent: 'transparent',
}

export const lightColors = {
  black: colors.black,
  primaryBg: colors.offWhite,
  secondaryBg: colors.white,
  primary: colors.black,
  secondary: colors.white,
  accent: colors.accent,
  white: colors.white,
  transparent: colors.transparent,
}

export const darkColors = {
  black: colors.black,
  primaryBg: colors.black,
  secondaryBg: colors.black,
  primary: colors.white,
  accent: colors.accent,
  white: colors.white,
  transparent: colors.transparent,
}
// Imports

import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers/root';

// Middleware

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger)
} 

// State

const initialState = {};

// Store

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middlewares))
);

export default store;
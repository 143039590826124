export function colorForCategory(cat) {
  if (cat === '24 Hrs') {
    return '#616CB2';
  } else if (cat === 'Art') {
    return '#F07569';
  }  else if (cat === 'Coffee') {
    return '#F0EC72';
  }  else if (cat === 'Drink') {
    return '#46C2C9';
  }  else if (cat === 'Food') {
    return '#FCB648';
  } else if (cat === 'Nature') {
    return '#86CDAE';
  } else if (cat === 'Shop') {
    return '#FAC1C3';
  } else {
    return 'transparent';
  }
}
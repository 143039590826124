// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { flyToCoordinates } from '../../actions/map';
import { Paragraph } from '../paragraph';
import { FlexRow } from '../layouts';
import { OSImage } from '../images';

// Locations Header

export class LocationsHeader extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const coor = {
      longitude: -115.1487986,
      latitude:  36.167387
    }
    this.props.flyToCoordinates(coor)
  }

  render() {
    return (
      <FlexRow pt="20px" px="32px" minHeight={["370px", "300px"]} maxHeight={["400px", "300px"]}>
        <OSImage variant="link" onClick={this.handleClick} height="48px !important" width="auto !important" src="/os-wordmark.png" /> 
        <Paragraph variant="medium">
          Our love for local Las Vegas and its growing community inspired us to
          create a platform showcasing the best of the city.
          <br/><br/>
          Off-Strip is a ongoing collaboration between Deep Space Program
          and local artists/establishments to promote the valley’s 
          standout cultural attractions. We’re commited to businesses and 
          experiences that emphasize community growth.
        </Paragraph>
      </FlexRow>
    );
  }
}

export default connect(null, { flyToCoordinates})(LocationsHeader);

// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ColorDot } from '../color-categories/color-categories';
import { colorForCategory } from '../../utils/colors';
import { Paragraph } from '../paragraph';
import { FlexRow } from '../layouts';

// Locations Header

export class LocationsFilter extends Component {

  static propTypes = {
  }

  render() {
    return (
      <FlexRow bg="white" overflowX="scroll" flexWrap="nowrap" zIndex="5" position="sticky" top="0" px="32px" height="40px" maxHeight="40px" minHeight="40px" borderBottom="1px solid rgba(0,0,0,0.1)">
          <FlexRow mr="6px" minWidth="40px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Art")}/><Paragraph>Art</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="60px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Coffee")}/><Paragraph>Coffee</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="50px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Drink")}/><Paragraph>Drink</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="55px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Food")}/><Paragraph>Food</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="60px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Nature")}/><Paragraph>Nature</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="55px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("Shop")}/><Paragraph>Shop</Paragraph></FlexRow>
          <FlexRow mr="6px" minWidth="60px" width="auto" flexGrow="1"><ColorDot mr="4px" bg={colorForCategory("24 Hrs")}/><Paragraph>24 Hrs</Paragraph></FlexRow>
      </FlexRow>
    );
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {})(LocationsFilter);

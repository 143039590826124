// Imports

import React from 'react';
import { Provider } from 'react-redux';
import { hydrate, render } from 'react-dom';

import store from './store';
import App from './App';

// Root Element

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}

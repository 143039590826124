// Imports

/* global google */

import { GET_GOOGLE_PLACE } from './types';
import store from '../store';


// Google
const map = new google.maps.Map(document.createElement('div'));
const service = new google.maps.places.PlacesService(map)

// Actions

function callback(place, status) {
  if (status === google.maps.places.PlacesServiceStatus.OK) {
    store.dispatch({
      type: GET_GOOGLE_PLACE,
      payload: place
    });
  }
}

// GET Companies
export const getGooglePlaces = (places) => (dispatch) => {
  for (var i = 0; i < places.length; i++) {
    // You seem to have a pool of 9 or 10 requests to exhaust,
    // then you get another request every second therein. 
    (function (i) {
      setTimeout(function () {
          var request = {
            placeId: places[i].data.google_place_id[0].text,
            fields: ['formatted_address', 'formatted_phone_number', 'reviews', 'rating', 'opening_hours', 'price_level', 'name', 'place_id', 'types']
          };
        service.getDetails(request, callback);
      }, i < 9 ? 0 : 1000 * i);
    })(i);
  }
};
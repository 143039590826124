// Imports

import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// Dropdown

export class OSDropdown extends Component {

  render() {
    const { options } = this.props;
    const defaultOption = options[0]
    const optionsLength = options.length
    return (
      <Dropdown options={options.slice(1, optionsLength)} onChange={this._onSelect} value={defaultOption}/>
    );
  }
}

export default OSDropdown;

// Imports

import { GET_LOCATIONS, GET_GOOGLE_PLACE, MAKE_ACTIVE_LOCATION } from '../actions/types';

// State

const initialState = { locations: [], activeLocation: null };

// Helpers

function replaceInArray(array, payload) {
  let inArray = false
  let newArray = array.map((place, index) => {
    if (place.data.google_place_id[0].text !== payload.place_id) {
      return place
    } else {
      inArray = true
      return {...place, google_place_data: payload}
    }
  })
  if (inArray === false) {
    return array
  } else {
    return newArray
  }
}

// Reducer

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload
      };
    case GET_GOOGLE_PLACE:
      return {
        ...state,
        locations: replaceInArray(state.locations, action.payload)
      }
    case MAKE_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocation: action.payload
      }
    default:
      return state;
  }
}
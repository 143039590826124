// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LocationsHeader from './locations-header';
import LocationsFilter from './locations-filter';
import { FlexColumn, FlexRow } from '../layouts';
import { Paragraph } from '../paragraph';
import { OSImage } from '../images';
import Location  from './location';
import { LinkTo } from '../links';

// Locations Table

class LocationsTable extends Component {

  constructor(props) {
    super(props);
    this.activeLocationDiv = null;
  }

  static propTypes = {
    locations: PropTypes.array.isRequired,
  }

  componentDidUpdate(prevProps) {
    // Refs Creation
    if (prevProps.locations.length === 0 && this.props.locations.length > 0) {
      let refs = {}
      this.props.locations.forEach(loc => {
        refs[loc.data.google_place_id[0].text] = React.createRef();
      })
      this.refs = refs;
      this.forceUpdate();
    }
    // Scroll Handling
    if (prevProps.activeLocation !== this.props.activeLocation) {
      const scrollToDiv = this.refs[this.props.activeLocation].current
      if (this.activeLocationDiv !== null) {
        this.activeLocationDiv.classList.remove('activeLocation');
      }
      this.activeLocationDiv = scrollToDiv.nextSibling;
      this.activeLocationDiv.classList.add('activeLocation');
      this.activeLocationDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }

  render() {
    const { locations } = this.props;
    if (locations.length > 0) {
      return (
        <FlexRow zIndex="3" position="absolute" bg="white" width={["100%", "475px"]} height={["320px", "100%"]} bottom="0" maxHeight="100vh" overflowY="scroll" borderRight={["none", "1px solid rgba(0,0,0,0.6)"]} borderTop={["1px solid rgba(0,0,0,0.6)", "none"]}>
          <LocationsHeader />
          <LocationsFilter />
          {Object.keys(this.refs).length > 0 && this.props.locations.sort((a, b) => (a.data.title[0].text > b.data.title[0].text) ? 1 : -1).map(loc => {
            return (
              <>
                <div ref={this.refs[loc.data.google_place_id[0].text]}></div>
                <Location location={loc} key={loc.id} ref={this.refs[loc.data.google_place_id[0].text]}/>
              </>
            )
          })}
          <FlexRow bg="F1F1F1" width="100%" height="150px" maxHeight="150px" justifyContent="center">
            <FlexRow height="50px" maxHeight="50px" minHeight="50px" pl="32px">
              <LinkTo to="https://www.deepspaceprogram.com">
                <OSImage height="16px !important" width="16px !important" src="/dsp.png" />
                <Paragraph variant="medium" ml="6px">Created by Deep Space Program</Paragraph>
              </LinkTo>
            </FlexRow>
            <FlexRow height="50px" maxHeight="50px" minHeight="50px" pl="32px" width="100%">
              <LinkTo to="https://www.filthylittlehands.com"><Paragraph variant="medium">Artwork by Marc Pacifici</Paragraph></LinkTo>
            </FlexRow>
            <FlexRow height="50px" maxHeight="50px" minHeight="50px" pl="32px" width="100%">
              <Paragraph variant="medium">© 2019 Off-Strip</Paragraph>
            </FlexRow>
          </FlexRow>
        </FlexRow>
      );
    }
    return (
      <FlexColumn height="100%" flexBasis="30%" borderRight="1px solid grey">
      </FlexColumn>
    )
  }
}

const mapStateToProps = state => ({
  locations: state.locationsAndPlacesReducer.locations,
  activeLocation: state.locationsAndPlacesReducer.activeLocation
})

LocationsTable.defaultProps = {
  locations: [],
  activeLocation: null
}

export default connect(
  mapStateToProps,
  { }
)(LocationsTable);

// Imports

import { FLY_TO } from './types';

// Actions

// Fly To
export const flyToCoordinates = coor => {
  return {
    type: FLY_TO,
    payload: coor,
  };
};
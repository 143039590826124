
// Imports

import { color, layout, space, typography, variant } from 'styled-system'
import styled from 'styled-components';

// Variants

const imageVariants = {
  listIcon: {
    height: '80px',
    width: '80px',
  },
  link: {
    cursor: 'pointer',
  }
}

// Components

const OSImage = styled.img(
  color,
  space,
  layout,
  typography,
  variant({
    scale: 'images',
    variants: imageVariants,
  })
)

OSImage.defaultProps = {
  variant: 'listIcon',
}

export { OSImage }
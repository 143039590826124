// Imports

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AlertTemplate from 'react-alert-template-basic';
import { Provider as AlertProvider } from 'react-alert';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NotFoundPage from './components/pages/errors/not-found';
import { lightTheme } from './styles/themes.js';
import IndexPage from './components/pages/index/index';
import { getLocations } from './actions/locations';
import Alerts from './components/alerts/alerts';
import GlobalStyle from './global-style';

// Options

const alertOptions = {
  timeout: 3000,
  position: 'bottom center'
};

// App

class App extends React.Component {

  state = {}

  static propTypes = {
    getLocations: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
  }

  componentDidMount() {
    this.props.getLocations();
  }

  render() {
    return (
      <ThemeProvider theme={lightTheme}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <Fragment>
              <GlobalStyle />
              <Alerts />
              <Switch>
                <Route exact path="/" component={IndexPage}/>
                <Route component={NotFoundPage}/>
              </Switch>
            </Fragment>
          </Router>
        </AlertProvider>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { getLocations }
)(App);

// Imports

import { color, layout, space, typography, variant } from 'styled-system'
import styled from 'styled-components';

// Variants

const titleVariants = {
  large: {
    backgroundColor: 'transparent',
    color: 'primary',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '32px',
  }
}

// Components

const Title = styled.h2(
  {
    boxSizing: 'border-box',
    marginBottom: 0,
    marginTop: 0,
    textAlign: 'left',
  },
  color,
  space,
  layout,
  typography,
  variant({
    scale: 'titles',
    variants: titleVariants,
  })
)

Title.defaultProps = {
  variant: 'large',
}

export { Title }
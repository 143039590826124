// Imports

import { border, color, layout, flexbox, space, position } from 'styled-system';
import styled from 'styled-components';

// Sub Componen

export const ColorDot = styled.div(
  {
    borderRadius: "100%",
    height: "10px",
    width: "10px",
  },
  border,
  space,
  color,
  layout,
  position,
  flexbox,
)

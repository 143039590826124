// Imports

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { FlexColumn } from '../layouts';
import Map from './map';

// Map Container

const MapSubContainer = styled(FlexColumn)`
  height: 100%;
  position: fixed;
  right: 0;
  width: calc(100% - 475px) !important;
  @media (max-width: 768px) {
    height: calc(100vh - 320px) !important;
    top: 0;
    width: 100% !important;
  }
`

// Header

export class MapContainer extends Component {

  static propTypes = {
  }

  render() {
    return (
      <MapSubContainer zIndex="1">
        <Map />
      </MapSubContainer>
    );
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {})(MapContainer);

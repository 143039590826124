// Imports

import { FLY_TO } from '../actions/types';

// State

const initialState = { flyTo: {longitude: null, latitude: null} };

// Reducer

export default function(state = initialState, action) {
  switch (action.type) {
    case FLY_TO:
      return {
        ...state,
        flyTo: {longitude: action.payload.longitude, latitude: action.payload.latitude}
      };
    default:
      return state;
  }
}

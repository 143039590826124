// Imports

import Prismic from 'prismic-javascript';

import { getGooglePlaces } from './places';
import Client from '../clients/prismic';
import { returnErrors } from './errors';
import { GET_LOCATIONS, MAKE_ACTIVE_LOCATION } from './types';

// Actions

// GET Locations
export const getLocations = () => (dispatch, getState) => {
  Client.query(
    Prismic.Predicates.at('document.type', 'location'),
    { pageSize: 50 }
  )
  .then(res => {
    dispatch(getGooglePlaces(res.results))
    dispatch({
      type: GET_LOCATIONS,
      payload: res.results,
    });
  })
  .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const makeActiveLocation = googlePlaceId => {
  return {
    type: MAKE_ACTIVE_LOCATION,
    payload: googlePlaceId,
  };
};
// Imports

import React from 'react';

import LocationsTable from '../../locations/locations-table';
import { MapContainer } from '../../map/map-container';

// Page

class IndexPage extends React.Component {
  render() {
    return (
      <>
        <LocationsTable />
        <MapContainer />
      </>
    );
  }
}

export default IndexPage;

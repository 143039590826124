import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box !important;
    font-family: 'Avenir';
    font-weight: 500;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
  html {
    box-sizing: border-box !important;
    font-family: 'Avenir';
    font-weight: 500;
    margin: 0 !important;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
  body {
    @import url('https://fonts.googleapis.com/css?family=Avenir:300,400, 500, 700&display=swap');
    background-color: white !important;
    box-sizing: border-box !important;
    font-family: 'Avenir';
    font-weight: 500;
    margin: 0 !important;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
  a {
    color: #231F20 !important;
    cursor: pointer;
  }
  a:hover {
    color: #FCB648 !important;
  }
  a:visited {
    color: #231F20 !important;
  }
  .marker {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    cursor: pointer;
    height: 48px;
    width: 48px;
    background-color: rgba(0, 0, 0, 0);
  }
  .Dropdown-arrow {
    top: 6px !important;
  }
  .Dropdown-control {
    border-radius: 6px;
    font-size: 12px;
    padding-bottom: 2px !important;
    padding-right: 30px !important;
    padding-top: 2px !important;
    width: 180px;
  }
  .Dropdown-option {
    font-size: 12px;
  }
  .activeLocation {
    background-color: #FBEFCD !important;
    border-bottom: 1px solid rgba(0,0,0,0.06);
    border-top: 1px solid rgba(0,0,0,0.06);
  }
`
export default GlobalStyle;

// Imports

import { border, color, layout, flexbox, space, position, typography } from 'styled-system';
import React, { Component } from 'react';
import { FaPhone, FaLocationArrow, FaGlobe } from 'react-icons/fa';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ColorDot } from '../color-categories/color-categories';
import { makeActiveLocation } from '../../actions/locations';
import { colorForCategory } from '../../utils/colors';
import { convertHours } from '../../utils/hours';
import { FlexColumn, FlexRow } from '../layouts';
import { flyToCoordinates } from '../../actions/map';
import { OSDropdown } from '../dropdown/dropdown';
import { Paragraph } from '../paragraph';
import { OSImage } from '../images';
import { Title } from '../headers';
import { Icon } from '../icons';

// Sub Component

const LocationContainer = styled(FlexColumn)(
  {
    cursor: "pointer",
    height: "auto",
    maxHeight: "none",
    "&:hover h2": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#FFF8EB",
    }
  },
  border,
  space,
  color,
  layout,
  position,
  flexbox,
  typography
)

// Location

class Location extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    flyToCoordinates: PropTypes.func.isRequired,
    makeActiveLocation: PropTypes.func.isRequired,
  }

  handleClick(e) {
    const { location } = this.props;
    const coor = {
      longitude: location.data.longitude,
      latitude: location.data.latitude
    }
    this.props.flyToCoordinates(coor)
    this.props.makeActiveLocation(location.data.google_place_id[0].text)
  }

  render() {
    const { location } = this.props;
    const offstripData = location.data;
    const googleData = location.google_place_data;
    let openingHours = null;
    if (googleData !== undefined && googleData.opening_hours){
      openingHours = convertHours(googleData.opening_hours)
    }
    return (
      <LocationContainer position="relative" className={this.props.className} onClick={this.handleClick} alignItems="center" px="32px" pt="40px" pb="10px">
        <FlexRow height="auto" maxHeight="250px" minHeight="100px" borderBottom="1px solid rgba(0,0,0,0.03)" flexWrap="nowrap">
          <FlexColumn height="100px" width="80px" mr="12px" flexGrow={0} alignItems="center" justifyContent="center">
            <OSImage src={offstripData.icon_image.url} />
          </FlexColumn>
          <FlexColumn width="auto" flexGrow="1" alignItems="flex-start" justifyContent="flex-start">
            <Title bg="transparent" mb="10px">{offstripData.title[0].text}</Title>
            <FlexRow mb="-4px" justifyContent="space-between" alignItems="flex-start" textAlign="left" flexWrap="wrap">
              {(openingHours && openingHours.weekday_text) && 
                <FlexRow order={[2, 1]} mb={["10px","0"]} justifyContent="flex-start" alignItems="center" textAlign="left" width={["100%", "auto"]}>
                  <OSDropdown options={openingHours.weekday_text}/>
                </FlexRow>
              }
              {!openingHours && 
                <FlexRow order={[2, 1]} mb={["10px","0"]} justifyContent="flex-start" alignItems="center" textAlign="left" width={["100%", "auto"]}>
                  —
                </FlexRow>
              }
              <FlexRow order={[1, 2]} justifyContent="flex-end" alignItems="flex-start" textAlign="right" width="auto">
                {offstripData.directions_url.url && 
                  <a href={`${offstripData.directions_url.url}`} target="_blank" rel="noopener noreferrer"><Icon ml="6px" variant='small' icon={[<FaLocationArrow />]} color='primary' mb={16} mr={10} /></a>
                }
                {offstripData.phone_number[0] && 
                  <a href={`tel:${offstripData.phone_number[0].text}`}><Icon ml="6px" variant='small' icon={[<FaPhone />]} color='primary' mb={16} mr={10} /></a>
                }
                {offstripData.website_url.url && 
                  <a href={`${offstripData.website_url.url}`} target="_blank" rel="noopener noreferrer"><Icon ml="6px" variant='small' icon={[<FaGlobe />]} color='primary' mb={16} mr={10} /></a>
                }
              </FlexRow>
            </FlexRow>
            <FlexRow justifyContent="flex-start" alignItems="center" textAlign="left" mb="12px">
              {(offstripData && offstripData.categories) && offstripData.categories.sort((a, b) => (a.category[0].text > b.category[0].text) ? 1 : -1).map(cat => {
                  return <ColorDot mt="2px" bg={colorForCategory(cat.category[0].text)} ml="3px" />
              })}
              {(offstripData && offstripData.categories) && <Paragraph variant="small" mx="6px">•</Paragraph>}
              <Paragraph variant="small" mr="6px">{offstripData.kind[0].text}</Paragraph>
              <Paragraph variant="small" mr="6px">•</Paragraph>
              <Paragraph variant="small" mr="6px">{offstripData.price[0].text}</Paragraph>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
        <FlexColumn minHeight="0" height="auto" maxHeight="none" width="auto" alignItems="flex-start" justifyContent="flex-start" mt="16px">
           <Paragraph fontSize="14px" lineHeight="22px" fontWeight={400}>{offstripData.long_description[0] && offstripData.long_description[0].text}</Paragraph>
        </FlexColumn>
      </LocationContainer>
    );
  }
}

export default connect(null, { flyToCoordinates, makeActiveLocation })(Location);

// Imports

import React from "react";
import { color, layout, space, typography, variant } from 'styled-system';
import styled from 'styled-components';

import { FlexRow } from './layouts.js';
import { Link } from 'react-router-dom';

// Components

const UnstyledLink = (props) => {
  const { className, children } = props;
  return (
    <FlexRow justifyContent="flex-start" width='auto'>
      <Link className={className} {...props}>{children}</Link>
    </FlexRow>
  )
}

const linkVariants = {
  standard: {
    '&:hover': {
      color: 'primary',
    },
    '&:visited': {
      color: 'primary',
    },
  },
}

const LinkTo = styled(UnstyledLink)(
  {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'left',
    textDecoration: 'none',
    maxWidth: 'none',
    minWidth: 0,
    width: 'auto',
  },
  color,
  space,
  layout,
  typography,
  variant({
    scale: 'links',
    variants: linkVariants,
  })
)

LinkTo.defaultProps = {
  variant: 'standard',
}

export { LinkTo }
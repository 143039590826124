// Imports

import styled from 'styled-components'
import { border, color, layout, flexbox, space, position, typography } from 'styled-system'

// Components

export const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  }
)

export const FlexBox = styled(Box)(
  {
    display: 'flex',
  },
  layout,
  flexbox
)

export const FlexRow = styled(Box)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
  border,
  space,
  color,
  layout,
  position,
  flexbox,
  typography
)

export const FlexColumn = styled(Box)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  border,
  space,
  color,
  layout,
  position,
  flexbox,
  typography
)

export const Container = styled(Box)(
  layout,
  space
)

const Section = styled(FlexBox)(
  {
    as: "section",
    flexWrap: 'wrap',
    width: '100vw'
  },
  color,
  space,
  layout,
)

Section.defaultProps = {
  as: 'section'
}

export { Section }

export const GET_ERRORS = 'GET_ERRORS';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';

export const GET_LOCATIONS = 'GET_LOCATIONS';

export const FLY_TO = 'FLY_TO';

export const GET_GOOGLE_PLACE = 'GET_GOOGLE_PLACE';

export const MAKE_ACTIVE_LOCATION = 'MAKE_ACTIVE_LOCATION';

// Imports

import { combineReducers } from 'redux';

import locationsAndPlacesReducer from './locations-and-places';
import messagesReducer from './messages';
import errorsReducer from './errors';
import mapReducer from './map';

// Root Reducer

export default combineReducers({
  locationsAndPlacesReducer,
  messagesReducer,
  errorsReducer,
  mapReducer
});

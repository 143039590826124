// Imports

import React, { Fragment } from 'react';

// Page

class NotFoundPage extends React.Component {
  render() {
    return (
      <Fragment>
       404 Page Not Found
      </Fragment>
    );
  }
}

export default NotFoundPage;

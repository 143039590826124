function abbrvDays(day) {
  let newStr = day.replace('Monday:','Mon:').replace('Tuesday:','Tue:').replace('Wednesday:','Wed:').replace('Thursday:','Thu:').replace('Friday:','Fri:').replace('Saturday:','Sat:').replace('Sunday:','Sun:').replace(/\s/g, '').replace('Open24hours','24 Hrs');
  newStr = newStr.substr(0, 4) + " " + newStr.substr(4,newStr.length)
  return newStr
} 

function convertHours(opening_hours) {
 var newWeekdayText = opening_hours.weekday_text.map(day => {
  return abbrvDays(day)
 })
 opening_hours['weekday_text'] = newWeekdayText
 return opening_hours
}

export { convertHours }


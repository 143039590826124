// Imports

import React from "react";
import { color, layout, space, typography, variant } from 'styled-system'
import styled from 'styled-components';

// Variants

const iconVariants = {
  large: {
    height: 80,
    width: 80,
    '*': {
      fontSize: 80,
      maxHeight: 80,
      maxWidth: 80,
    }
  },
  medium: {
    height: 30,
    width: 30,
    '*': {
      fontSize: 30,
      maxHeight: 30,
      maxWidth: 30,
    }
  },
  small: {
    height: 18,
    width: 18,
    '*': {
      fontSize: 18,
      maxHeight: 18,
      maxWidth: 18,
    }
  },
  xSmall: {
    height: 8,
    width: 8,
    '*': {
      fontSize: 8,
      maxHeight: 8,
      maxWidth: 8,
    }
  }
}

// Components

const UnstyledIcon = (props) => {
  const { icon, className } = props;
  return (<div className={className}>{icon[0]}</div>);
}

const Icon = styled(UnstyledIcon)(
  { 
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  color,
  space,
  layout,
  typography,
  variant({
    scale: 'icons',
    variants: iconVariants,
  })
)

Icon.defaultProps = {
  variant: 'medium'
}

export { Icon }